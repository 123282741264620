.table-profile-data {
    text-align: left;
    border-collapse: collapse;

    td, th {
        padding: .5rem;
        border-bottom: 1px solid $baby-blue;

        &:first-child {
            font-weight: bold;
        }
    }

    tr:first-child {
        td, th {          
            border-top: 1px solid $baby-blue;
        }
    }
}
