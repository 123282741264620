body {
    color: $navy;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: .875rem;
    margin: 0;
    overflow-x: hidden;
    line-height: 1.5;
    @media(min-width: $lg){
        font-size: 1rem;
    }
}

.container {
    padding: 1rem;
    margin: 0 auto 5.5rem;

    @media(min-width: $sm) {max-width: 540px;}
    @media(min-width: $md) {max-width: 720px;}
    @media(min-width: $lg) {max-width: 960px;}

    @media(min-width: $lg) {max-width: 1000px;}
    @media(min-width: $xl) {max-width: 1200px;}
}

a {
    color: $primary;
}

.visually-hidden{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

img {
    width: 100%;
    height: auto;
}