.documentation-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-end;
}

.document {
    text-align: center;
    width: 50%;
    margin-bottom: 1.5rem;

    &__img {
        max-width: 200px;
    }
}