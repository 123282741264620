.personalized-services {
  .form-label {
    margin: 0 0 1rem;
  }

  .symbol {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    position: absolute;
    left: -1.5rem;
    bottom: 0;

    &--essential {
      background: url("../assets/images/volet-essentiel.png") no-repeat center;
    }
    &--well-being {
      background: url("../assets/images/volet-bien-etre.png") no-repeat center;
    }
    &--social {
      background: url("../assets/images/volet-social.png") no-repeat center;
    }
  }
  
  &__legend {
    max-width: 475px;
    min-width: 345px;
    margin: 0 auto;
    background-color: $light-blue;
    padding: 1rem;

    p {
      margin-top: 0;
      text-align: center;
    }

    ul {
      list-style: none;
      padding-left: 0;
      width: 150px;
      margin: 0 auto;

      li {
        margin-bottom: .5rem;
      }
    }

    .symbol {
      position: relative;
      left: unset;
    }
  }
}

.list-services {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 3rem;

  li {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color:$red;
    position: relative;
    width: 45%;

    @media (min-width: $md) {
      width: 25%;
    }

    a {
      margin: 0 auto;
    }
  }

  &--secondary {
    li {
      max-width: none;
      text-align: left;
      width: 100%;
      margin-bottom: 1rem;
      @media (min-width: $md) {
        width: 45%;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 3px solid $red;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    margin-bottom: 0.75rem;

    &:hover,
    &:focus {
      box-shadow: 0 0 0.5rem rgba(201, 45, 44, 1);
    }

    @media (min-width: $sm) {
      height: 100px;
      width: 100px;
    }

    &--hebergement {
      &:before {
        background: url("../assets/images/icons/hebergement.png") no-repeat
          center;
      }
    }

    &--stationnement {
      &:before {
        background: url("../assets/images/icons/stationnement.png") no-repeat
          center;
      }
    }

    &--parrainage {
      &:before {
        background: url("../assets/images/icons/parrainage.png") no-repeat
          center;
      }
    }

    &--aide-financiere {
      &:before {
        background: url("../assets/images/icons/aide-financiere.png") no-repeat
          center;
      }
      & + .symbol {
        @media(min-width: $lg) {
          bottom: 1.5rem;
        }    
      }
    }

    &--groupe-partage {
      &:before {
        background: url("../assets/images/icons/groupe-partage.png") no-repeat
          center;
      }

      & + .symbol {
        bottom: 1.15rem;

        @media(min-width: $lg) {
          bottom: 1.5rem;
        } 
      } 
    }
    &--meditation {
      &:before {
        background: url("../assets/images/icons/meditation.png") no-repeat
          center;
      }
    }
    &--reinsertion {
      &:before {
        background: url("../assets/images/icons/activites.png") no-repeat center;
      }

      & + .symbol {
        bottom: 1.15rem;

        @media(min-width: $lg) {
          bottom: 1.5rem;
        } 
      }  
    }
    &--maquillage {
      &:before {
        background: url("../assets/images/icons/maquillage.png") no-repeat
          center;
      }
      & + .symbol {
        bottom: 2.5rem;

        @media(min-width: $lg) {
          bottom: 3rem;
        }  
      } 
    }
    &--massotherapie {
      &:before {
        background: url("../assets/images/icons/massotherapie.png") no-repeat
          center;
      }
    }
    &--scolaire {
      &:before {
        background: url("../assets/images/icons/scolaire.png") no-repeat center;
      }
      & + .symbol {
        bottom: 1.15rem;

        @media(min-width: $lg) {
          bottom: 1.5rem;
        } 
      } 
    }
    &--sira {
      &:before {
        background: url("../assets/images/icons/sira.png") no-repeat center;
      }
    }
    &--rencontre {
      &:before {
        background: url("../assets/images/icons/rencontre.png") no-repeat center;
      }

      & + .symbol {
        bottom: 2.5rem;
        
        @media(min-width: $lg) {
          bottom: 3rem;
        }  
      }  
    }
    &--camp-ete {
      &:before {
        background: url("../assets/images/icons/camp.png") no-repeat center;
      }
    }

    &--essential {
      position: relative;

      &:after {
        background: url("../assets/images/volet-essentiel.png") no-repeat center;
      }
    }
    &--well-being {
      position: relative;

      &:after {
        background: url("../assets/images/volet-bien-etre.png") no-repeat center;
      }
    }

    &--social {
      position: relative;

      &:after {
        background: url("../assets/images/volet-social.png") no-repeat center;
      }
    }

    &:before {
      content: "";
      background-size: contain;
      display: inline-block;

      z-index: -1;
      height: 45px;
      width: 45px;

      @media (min-width: $sm) {
        height: 60px;
        width: 60px;
      }
    }
  }

  //Liste de services
  &__service {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    color: $red;
    max-width: 115px;
    text-decoration: none;
    position: relative;

    &--secondary {
      text-align: left;
      flex-flow: row nowrap;
      align-items: center;
      max-width: none;

      // li {
      //   margin-bottom: 1rem;
      // }

      .icon {
        height: 50px;
        width: 50px;
        border-width: 2px;
        margin-bottom: 0;
        margin-right: 0.5rem;

        &:before {
          height: 35px;
          width: 35px;
        }
      }

      .symbol {
        position: unset;
        left: unset;
        margin-right: .25rem;
      }
    }
  }
}

