.center-content {
    .entry-content, article {
        max-width: 600px;
        margin: auto;

        .bloc-connexion{
            padding: .75rem 2rem 1rem;

            @media(min-width: $sm) {
                margin: 3rem;
            }
        }
    }
}