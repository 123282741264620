@font-face {
  font-family: 'Kalam';
  src: url("../assets/fonts/Kalam-Regular.woff2") format("woff2"), url("../assets/fonts/Kalam-Regular.woff") format("woff"), url("../assets/fonts/Kalam-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kalam';
  src: url("../assets/fonts/Kalam-Bold.woff2") format("woff2"), url("../assets/fonts/Kalam-Bold.woff") format("woff"), url("../assets/fonts/Kalam-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Light.woff2") format("woff2"), url("../assets/fonts/Roboto-Light.woff") format("woff"), url("../assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-LightItalic.woff2") format("woff2"), url("../assets/fonts/Roboto-LightItalic.woff") format("woff"), url("../assets/fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Regular.woff2") format("woff2"), url("../assets/fonts/Roboto-Regular.woff") format("woff"), url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Italic.woff2") format("woff2"), url("../assets/fonts/Roboto-Italic.woff") format("woff"), url("../assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Medium.woff2") format("woff2"), url("../assets/fonts/Roboto-Medium.woff") format("woff"), url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-MediumItalic.woff2") format("woff2"), url("../assets/fonts/Roboto-MediumItalic.woff") format("woff"), url("../assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Bold.woff2") format("woff2"), url("../assets/fonts/Roboto-Bold.woff") format("woff"), url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-BoldItalic.woff2") format("woff2"), url("../assets/fonts/Roboto-BoldItalic.woff") format("woff"), url("../assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

h1 a, h2 a, h3 a, h4 a, h5 a {
  text-decoration: none;
}

h1 {
  font-weight: 300;
  font-size: 32px;
}

.header-top {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 2rem;
  font-weight: 500;
}

.header-top a {
  color: #455560;
  text-decoration: none;
}

@media (min-width: 768px) {
  .header-top {
    margin-top: 1.5rem;
  }
}

@media (min-height: 769x) {
  .header-top {
    margin-bottom: 1.5rem;
  }
}

.header-top--centered {
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}

.header-top--services {
  padding: 1rem;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .header-top--services {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .header-top--services {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .header-top--services {
    max-width: 1000px;
    margin-top: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .header-top--services {
    max-width: 1200px;
  }
}

.header-top__logo {
  max-width: 150px;
}

@media (min-width: 768px) {
  .header-top__logo {
    position: absolute;
    top: 1.25rem;
  }
}

.header-top__logo img {
  width: 100%;
  height: auto;
}

.header-top__flam-zone {
  border-bottom: 2px solid lightgray;
  max-height: 100px;
  width: 100%;
  overflow: hidden;
  padding-left: 2rem;
  margin-bottom: 2.5rem;
  text-align: center;
}

@media (min-width: 576px) {
  .header-top__flam-zone {
    text-align: left;
  }
}

.header-top__flam-zone .logo-flam {
  max-width: 225px;
  width: 100%;
}

.header-top__menus {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  position: relative;
  top: -3.5rem;
}

@media (min-width: 768px) {
  .header-top__menus {
    width: auto;
    order: unset;
    top: unset;
  }
}

.header-top__menus .container-quick-menu {
  order: 2;
}

@media (min-width: 768px) {
  .header-top__menus .container-quick-menu {
    order: unset;
  }
}

.header-top__menus .container-main-menu {
  order: 1;
}

@media (min-width: 768px) {
  .header-top__menus .container-main-menu {
    order: unset;
  }
}

.header-top__menus ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  list-style: none;
  padding-left: 0;
}

.header-top__menus .quick-menu {
  display: none;
  justify-content: flex-end;
  margin-top: 0;
}

@media (min-width: 768px) {
  .header-top__menus .quick-menu {
    display: flex !important;
  }
}

.header-top__menus .quick-menu .menu-item {
  padding: .5rem;
}

@media (min-width: 768px) {
  .header-top__menus .quick-menu .menu-item {
    padding: .25rem;
    margin-left: 1rem;
  }
}

.header-top__menus .quick-menu .menu-item a {
  color: #455560;
  text-transform: uppercase;
}

.header-top__menus .quick-menu .wnbell-dropdown-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #C92D2C;
  background-color: #C92D2C;
  border-radius: 50%;
  width: 33px;
  margin-left: 1rem;
}

.header-top__menus .quick-menu .wnbell-dropdown-nav a {
  color: #FFFFFF;
}

.header-top__menus .quick-menu .wnbell-dropdown-nav .wnbell-icon-badge-container {
  top: .15rem;
}

.header-top__menus .quick-menu .wnbell-dropdown-menu {
  top: auto;
  bottom: -5rem;
  right: 0;
}

@media (min-width: 768px) {
  .header-top__menus .quick-menu .wnbell-dropdown-menu {
    top: 2.25rem;
    bottom: auto;
  }
}

.header-top__menus .quick-menu .wnbell-dropdown-menu .wnbell-closebtn-menu {
  display: none;
}

.header-top__menus .quick-menu .wnbell-dropdown-menu .wnbell_notification_item:hover {
  border: none;
}

.header-top__menus .quick-menu .wnbell-dropdown-menu .wnbell_notification_item:hover a {
  color: #C92D2C;
}

.header-top__menus .quick-menu .wnbell-dropdown-menu .wnbell_notification_item .notification-title {
  font-weight: bold;
}

.header-top__menus .main-menu {
  display: none;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .header-top__menus .main-menu {
    display: flex !important;
  }
}

.header-top__menus .main-menu .menu-item {
  padding: .5rem;
  border-bottom: 2px solid #7AA558;
}

.header-top__menus .main-menu .menu-item a {
  color: #7AA558;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .header-top__menus .main-menu .menu-item:first-child {
    padding-left: 0;
  }
}

.header-top__menus .main-menu .menu-item:last-child a:before {
  width: 26px;
  height: 17px;
  background: url("../assets/images/icons/video.png") no-repeat center;
}

@media (min-width: 768px) {
  .header-top__menus .main-menu .menu-item:last-child {
    padding-right: 0;
  }
}

.header-top__menus .main-menu .menu-item:nth-last-child(2) a:before {
  width: 16px;
  height: 20px;
  background: url("../assets/images/icons/documentation.png") no-repeat center;
}

.header-top__menus .main-menu .menu-item:nth-last-child(-n+2) {
  border-color: #455560;
}

@media (min-width: 768px) {
  .header-top__menus .main-menu .menu-item:nth-last-child(-n+2) {
    border-bottom: none;
    border-bottom: 0;
  }
}

.header-top__menus .main-menu .menu-item:nth-last-child(-n+2) a {
  color: #455560;
}

@media (min-width: 768px) {
  .header-top__menus .main-menu .menu-item:nth-last-child(-n+2) a {
    background-color: #455560;
    padding: .75rem 1rem;
    color: #FFFFFF;
  }
  .header-top__menus .main-menu .menu-item:nth-last-child(-n+2) a:before {
    content: "";
    display: inline-block;
    background-size: contain;
    vertical-align: sub;
    margin-right: .25rem;
  }
}

.header-top__menus .main-menu .current-menu-item {
  font-weight: bold;
  border-bottom: 5px solid #7AA558;
}

.header-top__menus .btn-menu {
  display: flex;
  justify-content: flex-end;
}

.header-top__menus .btn-menu button {
  display: inline-block;
  background-color: #455560;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  padding: 1.25rem .5rem;
}

.header-top__menus .btn-menu button:focus, .header-top__menus .btn-menu button:hover {
  outline: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .header-top__menus .btn-menu {
    display: none;
  }
}

.header-top__menus .mobile-menu {
  display: none;
  text-align: right;
}

@media (min-width: 768px) {
  .header-top__menus .mobile-menu {
    display: none;
  }
}

.wnbell-dropdown-menu-wrap {
  top: 2.5rem;
  right: 0;
}

.wnbell_notifications_lists_menu {
  background-color: #C92D2C;
  color: #FFFFFF;
  padding: .5rem;
}

.wnbell-dropdown-menu .wnbell_notification_item, .wnbell-dropdown-menu .wnbell_notification_item_menu {
  background-color: #C92D2C;
  color: #FFFFFF;
}

.wnbell-dropdown-menu .wnbell_notification_item a, .wnbell-dropdown-menu .wnbell_notification_item_menu a {
  color: #FFFFFF;
}

.wnbell-dropdown-menu .wnbell_notification_item_menu:hover a, .wnbell-dropdown-menu .wnbell_notification_item_menu:focus a {
  color: #C92D2C;
}

.footer {
  background-color: #94BAD3;
  width: 100%;
  text-align: center;
  padding: 0.75rem 2rem;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

@media (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: center;
  }
}

.footer--with-logo .footer-container {
  width: 100%;
  position: relative;
}

@media (min-width: 576px) {
  .footer--with-logo .footer-container {
    flex-direction: row;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .footer--with-logo .footer-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .footer--with-logo .footer-container {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .footer--with-logo .footer-container {
    max-width: 1200px;
  }
}

.footer__copyright, .footer__privacy-policy, .footer__contact {
  display: block;
  margin: 0.25rem 0;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
}

@media (min-width: 576px) {
  .footer__copyright, .footer__privacy-policy, .footer__contact {
    margin-left: 2rem;
    display: inline-block;
  }
}

.footer__logo {
  display: inline-block;
  max-width: 175px;
  width: 50%;
}

@media (min-width: 576px) {
  .footer__logo {
    width: 100%;
    position: absolute;
    top: -4.5rem;
    left: 1.5rem;
    margin-top: 0;
  }
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
}

#wrapper {
  min-height: 100vh;
  /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 168px;
  /* height of your footer */
}

@media (min-width: 576px) {
  #wrapper {
    padding-bottom: 51px;
  }
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.wp-block-button,
.button-link,
.wpforms-submit,
.wpforms-page-next {
  text-transform: uppercase !important;
  background-color: #455560 !important;
  color: white !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  border: none !important;
  font-weight: 500;
  text-decoration: none;
}

.button-link {
  display: inline-block;
  padding: .625rem 1rem;
}

.btn-retour {
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
}

.btn-retour__arrow {
  display: inline-block;
  background: url("../assets/images/icons/fleche.png") no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.btn-retour:hover, .btn-retour:focus {
  color: #7AA558;
}

.center-content .entry-content, .center-content article {
  max-width: 600px;
  margin: auto;
}

.center-content .entry-content .bloc-connexion, .center-content article .bloc-connexion {
  padding: .75rem 2rem 1rem;
}

@media (min-width: 576px) {
  .center-content .entry-content .bloc-connexion, .center-content article .bloc-connexion {
    margin: 3rem;
  }
}

.connection-title {
  text-align: center;
}

.form-connexion {
  display: block;
  max-width: 475px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem !important;
  background-color: #F4F8FA !important;
}

.documentation-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.document {
  text-align: center;
  width: 50%;
  margin-bottom: 1.5rem;
}

.document__img {
  max-width: 200px;
}

.table-profile-data {
  text-align: left;
  border-collapse: collapse;
}

.table-profile-data td, .table-profile-data th {
  padding: .5rem;
  border-bottom: 1px solid #94BAD3;
}

.table-profile-data td:first-child, .table-profile-data th:first-child {
  font-weight: bold;
}

.table-profile-data tr:first-child td, .table-profile-data tr:first-child th {
  border-top: 1px solid #94BAD3;
}

.personalized-services .form-label {
  margin: 0 0 1rem;
}

.personalized-services .symbol {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  position: absolute;
  left: -1.5rem;
  bottom: 0;
}

.personalized-services .symbol--essential {
  background: url("../assets/images/volet-essentiel.png") no-repeat center;
}

.personalized-services .symbol--well-being {
  background: url("../assets/images/volet-bien-etre.png") no-repeat center;
}

.personalized-services .symbol--social {
  background: url("../assets/images/volet-social.png") no-repeat center;
}

.personalized-services__legend {
  max-width: 475px;
  min-width: 345px;
  margin: 0 auto;
  background-color: #F4F8FA;
  padding: 1rem;
}

.personalized-services__legend p {
  margin-top: 0;
  text-align: center;
}

.personalized-services__legend ul {
  list-style: none;
  padding-left: 0;
  width: 150px;
  margin: 0 auto;
}

.personalized-services__legend ul li {
  margin-bottom: .5rem;
}

.personalized-services__legend .symbol {
  position: relative;
  left: unset;
}

.list-services {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.list-services li {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #C92D2C;
  position: relative;
  width: 45%;
}

@media (min-width: 768px) {
  .list-services li {
    width: 25%;
  }
}

.list-services li a {
  margin: 0 auto;
}

.list-services--secondary li {
  max-width: none;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .list-services--secondary li {
    width: 45%;
  }
}

.list-services .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 3px solid #C92D2C;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  margin-bottom: 0.75rem;
}

.list-services .icon:hover, .list-services .icon:focus {
  box-shadow: 0 0 0.5rem #c92d2c;
}

@media (min-width: 576px) {
  .list-services .icon {
    height: 100px;
    width: 100px;
  }
}

.list-services .icon--hebergement:before {
  background: url("../assets/images/icons/hebergement.png") no-repeat center;
}

.list-services .icon--stationnement:before {
  background: url("../assets/images/icons/stationnement.png") no-repeat center;
}

.list-services .icon--parrainage:before {
  background: url("../assets/images/icons/parrainage.png") no-repeat center;
}

.list-services .icon--aide-financiere:before {
  background: url("../assets/images/icons/aide-financiere.png") no-repeat center;
}

@media (min-width: 992px) {
  .list-services .icon--aide-financiere + .symbol {
    bottom: 1.5rem;
  }
}

.list-services .icon--groupe-partage:before {
  background: url("../assets/images/icons/groupe-partage.png") no-repeat center;
}

.list-services .icon--groupe-partage + .symbol {
  bottom: 1.15rem;
}

@media (min-width: 992px) {
  .list-services .icon--groupe-partage + .symbol {
    bottom: 1.5rem;
  }
}

.list-services .icon--meditation:before {
  background: url("../assets/images/icons/meditation.png") no-repeat center;
}

.list-services .icon--reinsertion:before {
  background: url("../assets/images/icons/activites.png") no-repeat center;
}

.list-services .icon--reinsertion + .symbol {
  bottom: 1.15rem;
}

@media (min-width: 992px) {
  .list-services .icon--reinsertion + .symbol {
    bottom: 1.5rem;
  }
}

.list-services .icon--maquillage:before {
  background: url("../assets/images/icons/maquillage.png") no-repeat center;
}

.list-services .icon--maquillage + .symbol {
  bottom: 2.5rem;
}

@media (min-width: 992px) {
  .list-services .icon--maquillage + .symbol {
    bottom: 3rem;
  }
}

.list-services .icon--massotherapie:before {
  background: url("../assets/images/icons/massotherapie.png") no-repeat center;
}

.list-services .icon--scolaire:before {
  background: url("../assets/images/icons/scolaire.png") no-repeat center;
}

.list-services .icon--scolaire + .symbol {
  bottom: 1.15rem;
}

@media (min-width: 992px) {
  .list-services .icon--scolaire + .symbol {
    bottom: 1.5rem;
  }
}

.list-services .icon--sira:before {
  background: url("../assets/images/icons/sira.png") no-repeat center;
}

.list-services .icon--rencontre:before {
  background: url("../assets/images/icons/rencontre.png") no-repeat center;
}

.list-services .icon--rencontre + .symbol {
  bottom: 2.5rem;
}

@media (min-width: 992px) {
  .list-services .icon--rencontre + .symbol {
    bottom: 3rem;
  }
}

.list-services .icon--camp-ete:before {
  background: url("../assets/images/icons/camp.png") no-repeat center;
}

.list-services .icon--essential {
  position: relative;
}

.list-services .icon--essential:after {
  background: url("../assets/images/volet-essentiel.png") no-repeat center;
}

.list-services .icon--well-being {
  position: relative;
}

.list-services .icon--well-being:after {
  background: url("../assets/images/volet-bien-etre.png") no-repeat center;
}

.list-services .icon--social {
  position: relative;
}

.list-services .icon--social:after {
  background: url("../assets/images/volet-social.png") no-repeat center;
}

.list-services .icon:before {
  content: "";
  background-size: contain;
  display: inline-block;
  z-index: -1;
  height: 45px;
  width: 45px;
}

@media (min-width: 576px) {
  .list-services .icon:before {
    height: 60px;
    width: 60px;
  }
}

.list-services__service {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #C92D2C;
  max-width: 115px;
  text-decoration: none;
  position: relative;
}

.list-services__service--secondary {
  text-align: left;
  flex-flow: row nowrap;
  align-items: center;
  max-width: none;
}

.list-services__service--secondary .icon {
  height: 50px;
  width: 50px;
  border-width: 2px;
  margin-bottom: 0;
  margin-right: 0.5rem;
}

.list-services__service--secondary .icon:before {
  height: 35px;
  width: 35px;
}

.list-services__service--secondary .symbol {
  position: unset;
  left: unset;
  margin-right: .25rem;
}

.form-label {
  text-align: center;
  font-size: 36px !important;
  font-family: "Kalam", "Roboto", Arial, sans-serif;
  color: #7AA558;
}

div.wpforms-container-full .wpforms-form ul li {
  margin-bottom: .75rem !important;
}

.form-profile {
  margin-top: 2rem !important;
}

.form-profile .center-list {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

@media (min-width: 992px) {
  .form-profile .center-list ul {
    max-width: 75%;
  }
}

.form-profile input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #7AA558;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #455560;
}

.form-profile .wpforms-field-label {
  margin-bottom: 2rem !important;
}

.form-profile .wpforms-field-radio .wpforms-field-label {
  text-align: center;
  font-size: 36px !important;
  font-family: "Kalam", "Roboto", Arial, sans-serif;
  color: #7AA558;
}

.form-profile .wpforms-image-choices {
  align-items: flex-end;
}

.form-profile .wpforms-image-choices .wpforms-image-choices-image img {
  max-height: 140px;
}

.form-profile .wpforms-image-choices .wpforms-image-choices-image:after {
  background: #7AA558 !important;
}

.form-profile .wpforms-selected label {
  background-color: #F4F8FA !important;
  border-color: #F4F8FA !important;
}

.form-profile .wpforms-page-prev {
  background-color: transparent !important;
  color: #455560 !important;
  font-weight: 500 !important;
  border: none !important;
}

.form-profile .wpforms-page-prev:before {
  content: "< ";
  font-weight: 500;
  font-size: 1.25rem;
  vertical-align: middle;
}

.form-profile__info .wpforms-pagebreak-split {
  display: flex;
  justify-content: center;
}

.form-profile__info .wpforms-pagebreak-split .wpforms-page-button:only-child {
  float: none;
}

.form-profile__btn-submit {
  display: block !important;
  margin: 0 auto !important;
}

.form-profile .wpforms-pagebreak-split {
  margin-top: 4rem !important;
}

.form-profile label.wpforms-error {
  text-align: center;
}

.service-category {
  display: flex;
  flex-direction: row;
}

.service-category__info {
  display: flex;
  align-items: center;
  background-color: #F4F8FA;
  padding: 0.75rem;
  font-weight: bold;
  margin-right: 1rem;
  max-width: 115px;
  width: 100%;
  margin-bottom: 3px;
}

.service-category__info span {
  font-weight: normal;
  text-transform: uppercase;
  display: block;
  width: 100%;
}

.service-category .list-services--view-all {
  width: 100%;
  justify-content: flex-start;
}

.service-category .list-services__service {
  max-width: 130px;
  width: 100%;
  margin-right: 0.5rem;
}

.service-category .list-services__service .service-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 3px solid #C92D2C;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  margin-bottom: 0.75rem;
}

.service-category .list-services__service .service-btn:hover, .service-category .list-services__service .service-btn:focus {
  box-shadow: 0 0 0.5rem #c92d2c;
}

@media (min-width: 576px) {
  .service-category .list-services__service .service-btn {
    height: 100px;
    width: 100px;
  }
}

.service-category .list-services__service .service-btn .service-img {
  width: 60%;
  height: auto;
}

.service-body {
  max-width: 992px;
  margin: 3rem 0 5.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.service-body .service-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 3px solid #C92D2C;
  border-radius: 50%;
  height: 125px;
  width: 125px;
  margin-top: 3.5rem;
}

.service-body .service-img img {
  width: 70%;
  height: auto;
}

.service-body .service-content {
  display: inline-block;
  width: 70%;
}

.service-body .service-content h1 {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 1rem;
  margin-top: 0;
}

@media (min-width: 768px) {
  .service-body .service-content h1 {
    font-size: 32px;
  }
}

.service-body .service-content ul li,
.service-body .service-content ol li {
  margin-bottom: 1rem;
}

.service-request {
  display: block;
  background-color: #F4F8FA;
  margin-top: 2rem;
  padding: 1.5rem;
  text-align: center;
  font-weight: bold;
}

.service-request p {
  margin-top: 0;
}

@media (min-width: 768px) {
  .video-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.video-grid h3 {
  width: 100%;
}

@media (min-width: 768px) {
  .video-grid .video {
    width: 45%;
  }
}

.video-grid .video iframe {
  width: 100%;
  height: 295px;
}

@media (min-width: 768px) {
  .video-grid .video iframe {
    height: 175px;
  }
}

@media (min-width: 992px) {
  .video-grid .video iframe {
    height: 240px;
  }
}

@media (min-width: 1200px) {
  .video-grid .video iframe {
    height: 295px;
  }
}

body {
  color: #455560;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: .875rem;
  margin: 0;
  overflow-x: hidden;
  line-height: 1.5;
}

@media (min-width: 992px) {
  body {
    font-size: 1rem;
  }
}

.container {
  padding: 1rem;
  margin: 0 auto 5.5rem;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

a {
  color: #455560;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
}
