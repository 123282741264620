.service-category {
  display: flex;
  flex-direction: row;

  &__info {
    display: flex;
    align-items: center;
    background-color: $light-blue;
    padding: 0.75rem;
    font-weight: bold;
    margin-right: 1rem;
    max-width: 115px;
    width: 100%;
    margin-bottom: 3px;

    span {
      font-weight: normal;
      text-transform: uppercase;
      display: block;
      width: 100%;
    }
  }

  .list-services {
    &--view-all {
      width: 100%;
      justify-content: flex-start;
    }
    &__service {
      max-width: 130px;
      width: 100%;
      margin-right: 0.5rem;

      .service-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border: 3px solid $red;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        margin-bottom: 0.75rem;

        &:hover,
        &:focus {
          box-shadow: 0 0 0.5rem rgba(201, 45, 44, 1);
        }

        @media (min-width: $sm) {
          height: 100px;
          width: 100px;
        }

        .service-img {
          width: 60%;
          height: auto;
        }
      }
    }
  }
}

//Single

.service-body {
  max-width: 992px;
  margin: 3rem 0 5.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  .service-img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 3px solid $red;
    border-radius: 50%;
    height: 125px;
    width: 125px;
    margin-top: 3.5rem;
  
    img {
      width: 70%;
      height: auto;
    }
  }

  .service-content {
    display: inline-block;
    width: 70%;

    h1 {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 1rem;
      margin-top: 0;

      @media (min-width: $md) {
        font-size: 32px;
      }
    }
    ul,
    ol {
      li {
        margin-bottom: 1rem;
      }
    }
  }
}

.service-request {
  display: block;
  background-color: $light-blue;
  margin-top: 2rem;
  padding: 1.5rem;
  text-align: center;
  font-weight: bold;

  p {
    margin-top: 0;
  }
}
