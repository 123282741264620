.footer {
  background-color: $baby-blue;
  width: 100%;
  text-align: center;
  padding: 0.75rem 2rem;
  position: relative;

  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: center;
  }

  &--with-logo{
    .footer-container {   
      width: 100%; 
      position: relative;
      @media (min-width: $sm) {
        flex-direction: row;
        justify-content: center;
      }

      @media(min-width: $md) {max-width: 720px;}
      @media(min-width: $lg) {max-width: 1000px;}
      @media(min-width: $xl) {max-width: 1200px;}
    }
  }

  &__copyright,
  &__privacy-policy,
  &__contact {
    display: block;
    margin: 0.25rem 0;
    color: $white;
    text-transform: uppercase;
    text-decoration: none;

    @media (min-width: $sm) {
      margin-left: 2rem;
      display: inline-block;
    }
  }

  &__logo {
    display: inline-block;
    max-width: 175px;
    width: 50%;

    @media (min-width: $sm) {
      width: 100%;

      position: absolute;
      top: -4.5rem;
      left: 1.5rem;
      margin-top: 0;
    }
  }
}


* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  position: relative;
}
#wrapper {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 168px; /* height of your footer */

  @media (min-width: $sm) {
      padding-bottom: 51px;
  }
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
