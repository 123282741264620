.wp-block-button,
.button-link,
.wpforms-submit,
.wpforms-page-next {
    text-transform: uppercase !important;
    background-color: $primary !important;
    color: white !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
    border: none !important;
    font-weight: 500;
    text-decoration: none;
}

.button-link {
    display: inline-block;
    padding: .625rem 1rem;
}

.btn-retour{
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;

    &__arrow {
        display:inline-block;
        background: url("../assets/images/icons/fleche.png") no-repeat;
        background-size: contain;
        width: 14px;
        height: 14px;
    }

    &:hover, &:focus {
        color: $secondary;
    }
}