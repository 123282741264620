h1, h2, h3, h4, h5 {
    a {
        text-decoration: none;
    }   
}

h1 {
    font-weight: 300;
    font-size: 32px;
}
