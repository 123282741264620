.header-top {
    a {
        color: $primary;
        text-decoration: none;
    }

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 2rem;
    font-weight: 500;

    @media(min-width: $md) {
        margin-top: 1.5rem;
    }
     
    @media (min-height: 769x) {
        margin-bottom: 1.5rem;
    }

    &--centered {
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    &--services {
        padding: 1rem;
        margin: 0 auto;

        @media(min-width: $sm) {max-width: 540px;}
        @media(min-width: $md) {max-width: 720px;}
        @media(min-width: $lg) {max-width: 1000px; margin-top: 1.5rem;}
        @media(min-width: $xl) {max-width: 1200px;}
    }

    &__logo {
        max-width: 150px;
        
        @media(min-width: $md){
            position: absolute;
            top: 1.25rem;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__flam-zone {
        border-bottom:2px solid lightgray;
        max-height: 100px;
        width: 100%;
        overflow: hidden;
        padding-left: 2rem;
        margin-bottom: 2.5rem;
        text-align: center;

        @media(min-width: $sm) {
            text-align: left;
        }

        .logo-flam {
            max-width: 225px;
            width: 100%;
        }
    }

    &__menus {
        display: flex;
        flex-flow: column wrap;
        width: 100%;

        position: relative;
        top: -3.5rem;

        @media(min-width: $md) {
            width: auto;
            order: unset;
            top: unset
        }

        .container-quick-menu{
            order: 2;
            @media(min-width: $md) {
                order: unset;
            }
        }
        .container-main-menu {
            order: 1;
            @media(min-width: $md) {
                order: unset;
            }
        }

        ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            list-style: none;
            padding-left: 0;
        }

        .quick-menu {
            display: none;
            justify-content: flex-end;
            margin-top: 0;

            @media(min-width: $md) {
                display: flex !important;
            }

            .menu-item {  
                padding: .5rem;       
                @media(min-width: $md) {
                    padding: .25rem;
                    margin-left: 1rem;
                }

                a {
                    color: $primary; 
                    text-transform: uppercase;
                }             
            }

            .wnbell-dropdown-nav {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $red;
                background-color: $red;
                border-radius: 50%;
                width: 33px;
                margin-left: 1rem;

                a {
                    color: $white;
                }

                .wnbell-icon-badge-container {
                    top: .15rem;
                }
            } 

            .wnbell-dropdown-menu {
                top: auto;
                bottom: -5rem;
                right: 0;

                @media(min-width:$md) {
                    top: 2.25rem;
                    bottom: auto;
                }

                .wnbell-closebtn-menu {
                    display: none;
                }

                .wnbell_notification_item {

                    &:hover {
                        border:none;

                        a {
                            color: $red;
                        }
                    }
                    
                    .notification-title {
                        font-weight: bold;
                    }
                }
            }
        }

        .main-menu {
            display: none;
            margin-top: 1rem;

            @media(min-width: $md) {
                display: flex !important;
            }

            .menu-item {
                padding: .5rem;                
                border-bottom: 2px solid $secondary;

                a {
                    color: $secondary;
                    text-transform: uppercase;                   
                }

                &:first-child {
                    @media(min-width: $md) {
                        padding-left: 0;
                    }
                } 
                
                &:last-child {
                    a:before {
                        width:26px;
                        height:17px;
                        background: url("../assets/images/icons/video.png") no-repeat center;
                    } 

                    @media(min-width: $md) {
                        padding-right: 0;
                    }
                }
                &:nth-last-child(2) {
                    a:before {
                        width:16px;
                        height:20px;
                        background: url("../assets/images/icons/documentation.png") no-repeat center;
                    } 
                }
                &:nth-last-child(-n+2) {
                    border-color: $primary;

                    @media(min-width: $md) {                       
                        border-bottom: none;
                        border-bottom: 0;
                    }

                    a {
                         color: $primary;

                        @media(min-width: $md) {
                            background-color: $primary; 
                            padding: .75rem 1rem;
                            color: $white;

                            &:before {
                                content: "";
                                display:inline-block;
                                background-size: contain;
                                vertical-align: sub;
                                margin-right: .25rem;
                            } 
                        }
                    }
                }
            }

            .current-menu-item {
                font-weight: bold;
                border-bottom: 5px solid $secondary;
            }
        }

        .btn-menu {
            display: flex;
            justify-content: flex-end;

            button {
                display: inline-block;
                background-color: $primary;
                color: $white;
                text-transform: uppercase;
                font-weight: bold;
                border: none;
                padding: 1.25rem .5rem;

                &:focus, &:hover {
                    outline: none;
                    cursor: pointer;
                }
            }

            @media(min-width: $md) {
                display: none;
            }
        }

        .mobile-menu {
            display: none;
            text-align: right;

            @media(min-width: $md) {
                display: none;
            }
        }
    }
}

.wnbell-dropdown-menu-wrap {
    top: 2.5rem;
    right: 0;
}

.wnbell_notifications_lists_menu {
    background-color: $red;
    color: $white;
    padding: .5rem;
}

.wnbell-dropdown-menu {
     .wnbell_notification_item, .wnbell_notification_item_menu {
         background-color: $red;
         color: $white;

         a {
             color: $white;
         }
     }

     .wnbell_notification_item_menu {
         &:hover, &:focus {
             a {
                color: $red;
             }
         }

     }
}
