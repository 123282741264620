//Fonts
@font-face {
    font-family: 'Kalam';
    src: url('../assets/fonts/Kalam-Regular.woff2') format('woff2'),
         url('../assets/fonts/Kalam-Regular.woff') format('woff'),
         url('../assets/fonts/Kalam-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Kalam';
    src: url('../assets/fonts/Kalam-Bold.woff2') format('woff2'),
         url('../assets/fonts/Kalam-Bold.woff') format('woff'),
         url('../assets/fonts/Kalam-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Light.woff2') format('woff2'),
         url('../assets/fonts/Roboto-Light.woff') format('woff'),
         url('../assets/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-LightItalic.woff2') format('woff2'),
         url('../assets/fonts/Roboto-LightItalic.woff') format('woff'),
         url('../assets/fonts/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
         url('../assets/fonts/Roboto-Regular.woff') format('woff'),
         url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Italic.woff2') format('woff2'),
         url('../assets/fonts/Roboto-Italic.woff') format('woff'),
         url('../assets/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Medium.woff2') format('woff2'),
         url('../assets/fonts/Roboto-Medium.woff') format('woff'),
         url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-MediumItalic.woff2') format('woff2'),
         url('../assets/fonts/Roboto-MediumItalic.woff') format('woff'),
         url('../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
         url('../assets/fonts/Roboto-Bold.woff') format('woff'),
         url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-BoldItalic.woff2') format('woff2'),
         url('../assets/fonts/Roboto-BoldItalic.woff') format('woff'),
         url('../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

//Color palette
$navy: #455560;
$light-blue: #F4F8FA;
$baby-blue: #94BAD3;
$green: #7AA558;
$red: #C92D2C;

$white: #FFFFFF;
$black: #000000;

$primary: $navy;
$secondary: $green;
$background: $white;

//Breakpoints

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;