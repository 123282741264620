.video-grid {
    @media(min-width: $md){
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    } 

    h3 {
        width: 100%;
    }

    .video {
        @media(min-width: $md) {
            width: 45%;
        }

        iframe {
            width: 100%;
            height: 295px;
            @media(min-width: $md) {
                height: 175px;
            }
            @media(min-width: $lg) {
                height: 240px;
            }
            @media(min-width: $xl) {
                height: 295px;
            }
        }
    }
}