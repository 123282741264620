.connection-title {
    text-align: center;
}

.form-connexion {
    display: block;
    max-width: 475px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem !important;
    background-color: $light-blue !important;
}