.form-label  {
    text-align: center;
    font-size: 36px !important;
    font-family: "Kalam", "Roboto", Arial, sans-serif;
    color: $secondary;
} 

div.wpforms-container-full .wpforms-form ul li {
    margin-bottom: .75rem !important;
}

.form-profile { 
    margin-top: 2rem !important;

    .center-list {
        display: flex;
        flex-flow: column wrap;
        align-items: center;

        ul {
            @media(min-width: $lg) {
                max-width: 75%;
            }
        }
    } 

    input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: $green;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid $primary;
    }

    .wpforms-field-label {
        margin-bottom: 2rem !important;
    }

    .wpforms-field-radio {
        .wpforms-field-label  {
            text-align: center;
            font-size: 36px !important;
            font-family: "Kalam", "Roboto", Arial, sans-serif;
            color: $secondary;
        }  
    }

    .wpforms-image-choices {
        align-items: flex-end;      

        .wpforms-image-choices-image {
            img{
                max-height: 140px;
            }

            &:after {
                background: $green !important;
            }
        }
    }

    .wpforms-selected label{
        background-color: $light-blue !important;
        border-color: $light-blue !important;
    }

    .wpforms-page-prev {
        background-color: transparent !important;
        color: $primary !important;
        font-weight: 500 !important;
        border: none !important;

        &:before {
            content: "< ";
            font-weight: 500;
            font-size: 1.25rem;
            vertical-align: middle;
        }
    }

    &__info {
        .wpforms-pagebreak-split {
            display: flex;
            justify-content: center;

           .wpforms-page-button:only-child {
                float: none;
           }
        }     
    }

    &__btn-submit {
        display: block !important;
        margin: 0 auto !important;
    }

    .wpforms-pagebreak-split {
        margin-top: 4rem !important;
    }

    label.wpforms-error {
        text-align: center;
    }
}
